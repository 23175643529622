import { createSignal } from 'solid-js'
import { FiLoader } from 'solid-icons/fi'

const IBMLoginButton = () => {
  const [loading, setLoading] = createSignal(false)

  const getIBMLink = async () => {
    const redirectUri = [window.location.origin, '/ibm/auth'].join('')

    const url = new URL(
      [import.meta.env.PUBLIC_API_URL, '/auth/ibm/url'].join(''),
    )
    url.searchParams.append('redirectUri', redirectUri)

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        throw new ErrorHandler('Network response was not ok')
      }

      const result = await response.json()
      return result.data
    } catch (error) {
      new ErrorHandler('Error getting IBM link: ', error as string)
      return null
    }
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      const IBMLink = await getIBMLink()

      if (IBMLink) {
        window.location.href = IBMLink
      }
    } catch (error) {
      new ErrorHandler('Error getting IBM link: ', error as string)
    } finally {
      setLoading(false)
    }
  }

  return (
    <button
      type="button"
      class="flex h-14 w-60 items-center justify-center gap-2 rounded-full bg-white text-center text-base font-bold text-black shadow-md hover:bg-caribbean-green-10 active:shadow-lg disabled:cursor-not-allowed disabled:opacity-50 h-sm:h-10"
      onClick={handleClick}
      disabled={loading()}
    >
      {loading() ? (
        <FiLoader class="animate-spin" size={36} />
      ) : (
        <>
          <img
            src="/nyc-logo.webp"
            alt="NYC Login Icon"
            width={36}
            height={36}
          />
          <span class="whitespace-nowrap">Login</span>
        </>
      )}
    </button>
  )
}

export default IBMLoginButton
