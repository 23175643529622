import { createSignal } from 'solid-js'
import type { DOMElement } from 'solid-js/jsx-runtime'

import { completeStudentOnboarding } from '@/store'
import { navigate } from 'astro:transitions/client'

const OnboardingConfirmation = () => {
  const [loading, setLoading] = createSignal<boolean>(false)

  const handleCompleteStudentOnboarding = async (
    event: MouseEvent & {
      currentTarget: HTMLButtonElement
      target: DOMElement
    },
  ) => {
    event.preventDefault()

    try {
      setLoading(true)

      const response = await completeStudentOnboarding()
      if (response && response.childOnboardingUpdate.id) {
        await navigate('/home')
        return
      }

      throw new ErrorHandler('Error completing student onboarding')
    } catch (error) {
      new ErrorHandler(
        'We are having trouble right now. Please reload the page and try again.',
        error as string,
      ).showDialog('Oops!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div class="fixed bottom-0 left-0 right-0 flex w-full items-center justify-center pb-4 pt-2 sm:relative">
      <button
        id="next-button-confirmation"
        class="btn-primary-edsoma m-auto flex items-center justify-center h-sm:w-48 h-sm:text-sm"
        type="button"
        onClick={(event) => handleCompleteStudentOnboarding(event)}
        disabled={loading()}
      >
        {loading() ? (
          <span class="loading loading-spinner loading-xs" />
        ) : (
          'Next'
        )}
      </button>
    </div>
  )
}

export default OnboardingConfirmation
