import { atom } from 'nanostores'

import type { AgeRange } from '@/core/entities'
import { listAgeRanges } from '@/core/infra/graphql'

export const ageRanges = atom<AgeRange[]>([])
export const loadingAgeRanges = atom<boolean>(false)

export const loadAgeRanges = async () => {
  if (ageRanges.get().length) return

  try {
    loadingAgeRanges.set(true)
    const response = await listAgeRanges()
    const ageRangesList = response.ageRangeList
    ageRanges.set([...ageRangesList])
  } catch (error) {
    new ErrorHandler('', error as string)
  } finally {
    loadingAgeRanges.set(false)
  }
}

export const clearAgeRanges = () => {
  ageRanges.set([])
}
