import { atom } from 'nanostores'

import { type StudentInstruction, StudentInstructions } from '@/core/entities'
import {
  childInstructionFindOrCreate,
  childInstructionUpdate,
} from '@/core/infra/graphql'

export const bookVideoStore = atom<StudentInstruction | undefined>(undefined)
export const loadingBookVideoStore = atom<boolean>(false)
export const bookVideoShowStore = atom<boolean>(false)
export const bookCallbackStore = atom<undefined | (() => void)>()

export const loadBookVideo = async (childId: number): Promise<void> => {
  if (typeof bookVideoStore.get() !== 'undefined' || !childId) return

  try {
    loadingBookVideoStore.set(true)
    const response = await childInstructionFindOrCreate(
      childId,
      StudentInstructions.BOOK_READING_TUTORIAL,
    )
    bookVideoStore.set(response.childInstructionFindOrCreate)
  } catch (error) {
    new ErrorHandler('', error as string)
  } finally {
    loadingBookVideoStore.set(false)
  }
}

export const completeBookVideo = async (): Promise<void> => {
  const storeValue = bookVideoStore.get()
  if (typeof storeValue === 'undefined') return

  try {
    const response = await childInstructionUpdate(storeValue.id, 'COMPLETED')
    if (response.childInstructionUpdate.success)
      bookVideoStore.set({
        ...storeValue,
        status: 'COMPLETED',
      })
  } catch (error) {
    new ErrorHandler('', error as string)
  }
}

export const showBookVideo = async (): Promise<void> => {
  const storeValue = bookVideoStore.get()
  if (typeof storeValue === 'undefined') return
  bookVideoShowStore.set(true)
}

export const hideBookVideo = (): void => {
  bookVideoShowStore.set(false)
  const callback = bookCallbackStore.get()
  console.log('callback', callback)
  if (typeof callback === 'function') callback()
}
